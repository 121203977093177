import { Component } from 'react';
import * as language from '../constants/languages';
import { getAboutData, getSupportData } from '../services/services';
import SpinnerLoader from '../components/SpinnerLoader';
import HeaderCommon from '../components/HeaderCommon';
import Rectangle1 from '../assets/svg/aboutus/rectangle1.svg'
import Rectangle2 from '../assets/svg/aboutus/rectangle2.svg'
import Addressicon from '../assets/svg/aboutus/addressicon.svg'
import Email from '../assets/svg/aboutus/email.svg'
import Phone from '../assets/svg/aboutus/phone.svg'

class AboutUsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: '',
            number: []
        };

        this.state.email = []
        // this.offerData = this.props.route.params.offerDetails;
        // this.pageId = this.props.route.params.pageId; // 1 -> abouts us ; 2-> terms; 3 -> offer details
    }
    componentDidMount() {
        this.getStaticData();

    }
    async getStaticData() {

        this.getAboutData();
        this.getSupportInfoData();

    }
    // get about us data
    async getAboutData() {
        await getAboutData(language.companyidValue).then((aboutData) => {
            if (aboutData.status === "success") {
                this.setState({
                    data: aboutData.data,
                    isLoading: false
                })
            }
        })
    }

    async getSupportInfoData() {
        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {

                if (supportData.data.contactNos != null) {
                    if (supportData.data.contactNos.length > 1) {
                        this.setState({
                            number: supportData.data.contactNos.split(',')

                        })
                    } else {
                        this.setState({
                            number: supportData.data.contactNos

                        })

                    }
                } else {
                    this.setState({
                        number: []

                    })
                }
                if (supportData.data.contactEmail != null) {
                    if (supportData.data.contactEmail.length > 1) {
                        this.setState({
                            email: supportData.data.contactEmail.split(',')

                        })
                    } else {
                        this.setState({
                            // email: supportData.data.contactEmail
                            email: supportData.data.contactEmail

                        })

                    }
                } else {
                    this.setState({
                        email: []

                    })
                }
                if (supportData.data.contactus != null) {
                    if (supportData.data.contactus.length > 1) {
                        this.setState({
                            contactus: supportData.data.contactus.split(',')

                        })
                    } else {
                        this.setState({
                            contactus: supportData.data.contactus

                        })

                    }
                } else {
                    this.setState({
                        contactus: []

                    })
                }

            }
        })
    }

    render() {

        return (
            <div>
                <HeaderCommon />
                <div>
                    {
                        this.state.isLoading == true ?
                            <SpinnerLoader />
                            :
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginLeft: 15, flexDirection: 'Column' }}>

                                <div style={{ width: '100%', position: 'relative', minHeight: 250, }}>
                                    <img src={Rectangle1} style={{ position: 'absolute', top: 50, left: 120 }} height={180} width={180} />
                                    <img src={Rectangle2} style={{ position: 'absolute', top: 65, left: 80 }} height={150} width={180} />

                                    <img src={require('../assets/svg/aboutus/image1.png')} style={{ height: 170, width: 250, position: 'absolute', top: 30, left: 60 }} />
                                    <img src={require('../assets/svg/aboutus/image2.png')} style={{ height: 155, width: 93, position: 'absolute', top: 80, left: 230 }} />
                                </div>

                                <p
                                    style={{ fontFamily: 'Poppins-Regular' }}

                                >{this.state.data}</p>
                                <div style={{ marginVertical: 40 }}>

                                    {this.state.contactus?.length > 0 ?
                                        <>
                                            <p style={{ fontFamily: 'Montserrat-Medium', fontSize: 16, textDecorationLine: 'underline', }}>Contact Info</p>
                                            <div style={{ marginTop: 30, display: 'flex' }}>
                                                <div>
                                                <img src={Addressicon} />
                                                </div>
                                                <div style={{ marginLeft: 15, flex: 1 }}>
                                                    <p style={{ fontFamily: 'Montserrat-Medium', marginBottom: 10, }}>Address</p>

                                                    <p style={{ fontFamily: 'Montserrat-Regular', }}>{this.state.contactus}</p>
                                                </div>
                                            </div>
                                        </> : null
                                    }
                                    {this.state.number?.length > 0 ?
                                        <div style={{ marginTop: 30, display: 'flex' }}>
                                            <div >
                                                <img src={Phone} />
                                            </div>
                                            <div style={{ marginLeft: 15, flex: 1 }}>
                                                <p style={{ fontFamily: 'Montserrat-Medium', marginBottom: 10, }}>Phone</p>
                                                {
                                                    this.state.number?.map((number, index) => (
                                                        <p key={index} style={{ fontFamily: 'Montserrat-Regular', }}>{number}</p>

                                                    ))}
                                            </div>

                                        </div> : null
                                    }

                                    {
                                        this.state.email?.length > 0 ?
                                            <div style={{ marginTop: 30, display: 'flex' }}>
                                                <div >
                                                    <img src={Email} />

                                                </div>
                                                <div style={{ marginLeft: 15, flex: 1 }}>
                                                    <p style={{ fontFamily: 'Montserrat-Medium', margin: 0 }}>Email</p>
                                                    {
                                                        this.state.email.map((email, index) => (
                                                            <p key={index} style={{ fontFamily: 'Montserrat-Regular', }}>{email}</p>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>

                    }
                </div>
            </div>

        )
    }
}
export default AboutUsPage